if (document.URL.match(/company_accounts/))
{
    window.company_accounts = function (active_account = null, accounts_list = null)
    {
        if (document.URL.match(/reports/))
        {
            if (!localStorage.getItem('active_tab_reports'))
            {
                localStorage.setItem('active_tab_reports', '0');
            }
        }
        if (!accounts_list)
        {
            accounts_list = '';
        }

        return {
            active_account: active_account,
            show_view_transaction: false,
            show_transactions: false,
            show_related_transactions: false,
            show_select_accounts: false,
            show_edit_account: false,
            show_users: false,
            show_add_to_existing_item: false,
            show_email_transaction: false,
            add_category: false,
            add_bill_due_user: false,
            show_select_accounts: false,
            report_year: new Date().getFullYear(),
            chart_type: 'column',
            all_account_ids: accounts_list.split(','),
            quick_books_statement_dates: '',
            active_tab_reports: parseInt(localStorage.getItem('active_tab_reports')) || 0,
            show_email_reports_div: false,
            divs_to_email: '',
            show_vendors: false,

            init()
            {
                let scope = this;
                if (document.URL.match(/dashboard/))
                {
                    setTimeout(function ()
                    {
                        scope.all_account_ids.forEach(function (account_id)
                        {
                            scope.update_counts(account_id);
                        });
                    }, 1000);
                } else if (document.URL.match(/\/accounts/))
                {
                    scope.accounts_get_accounts();
                } else if (document.URL.match(/\/settings/))
                {
                    scope.get_categories();
                    scope.get_bill_due_alert();
                } else if (document.URL.match(/all_transactions/))
                {
                    setTimeout(function ()
                    {
                        document.querySelectorAll('.email-transaction-button').forEach(function (button)
                        {
                            button.remove();
                        });
                    }, 200);
                    setInterval(() =>
                    {
                        if (document.querySelectorAll('.email-transaction-button'))
                        {
                            document.querySelectorAll('.email-transaction-button').forEach(function (button)
                            {
                                button.remove();
                            });
                        }
                    }, 1000);
                } else if (document.URL.match(/reports/))
                {
                    scope.get_monthly_usage_reports();
                    scope.get_quick_books_data();
                }

            },
            dashboard_get_account(account_id)
            {
                let scope = this;
                let statement_dates = document.getElementById('statement_dates_' + account_id) ? document.getElementById('statement_dates_' + account_id).value : '';
                axios.get('/company_accounts/dashboard/dashboard_get_account', { params: { account_id: account_id, statement_dates: statement_dates } })
                    .then(function (response)
                    {
                        document.getElementById('account_' + account_id).innerHTML = response.data;
                        scope.dashboard_get_account_details(account_id);
                    })
                    .catch(function (error)
                    {
                    });
            },
            dashboard_get_account_details(account_id)
            {
                let scope = this;
                let statement_dates = document.getElementById('statement_dates_' + account_id).value;
                axios.get('/company_accounts/dashboard/dashboard_get_account_details', { params: { account_id: account_id, statement_dates: statement_dates } })
                    .then(function (response)
                    {
                        document.getElementById('account_details_' + account_id).innerHTML = response.data;
                        scope.all_transactions(account_id);
                        scope.no_user(account_id);
                        scope.no_category(account_id);
                        scope.rejected(account_id);
                    })
                    .catch(function (error)
                    {
                    });
            },
            accounts_get_accounts(active = 1)
            {
                let scope = this;
                ['credit_cards', 'bank_accounts'].forEach(function (account_type)
                {
                    axios.get('/company_accounts/get_accounts', { params: { account_type: account_type, active: active } })
                        .then(function (response)
                        {
                            if (account_type == 'credit_cards')
                            {
                                document.querySelector('#credit_card_accounts_div').innerHTML = response.data;
                            } else if (account_type == 'bank_accounts')
                            {
                                document.querySelector('#bank_accounts_div').innerHTML = response.data;
                            }
                        })
                        .catch(function (error)
                        {
                        });
                });
            },
            get_transactions(account_id)
            {
                let scope = this;
                let transactions_div = scope.$refs.transactions_div;
                scope.$refs.account_id.value = account_id;
                scope.show_bulk_options = false;
                document.querySelectorAll('bulk-options').forEach(function (el)
                {
                    el.classList.add('hidden');
                });
                axios.get('/company_accounts/get_transactions', { params: { account_id: account_id } })
                    .then(function (response)
                    {
                        transactions_div.innerHTML = response.data;
                        scope.show_transactions = true;
                        let options_container_interval = setInterval(function ()
                        {
                            let container = document.querySelector('.options-container');
                            if (container)
                            {
                                clearInterval(options_container_interval);
                                container.querySelectorAll('select', 'input').forEach(function (el)
                                {
                                    el.addEventListener('change', function ()
                                    {
                                        document.querySelectorAll('bulk-options').forEach(function (el)
                                        {
                                            el.classList.add('hidden');
                                        });
                                    });
                                });
                            }
                        }, 500);
                    })
                    .catch(function (error)
                    {
                    });
            },
            update_account(button, account_id, ele)
            {
                let scope = this;
                let button_html = button.innerHTML;
                show_loading_button(button, 'Updating ... ');
                axios.get('/company_accounts/update_account', {
                    params: {
                        account_id: account_id
                    },
                })
                    .then(function (response)
                    {
                        setTimeout(function ()
                        {
                            notify('success', 'Account successfully updated');
                            ele.innerText = response.data.last_updated;
                            button.innerHTML = button_html;
                            scope.dashboard_get_account(account_id);
                        }, 8000);
                    })
                    .catch(function (error)
                    {
                    });
            },

            bulk_options(container)
            {
                setTimeout(function ()
                {
                    if (container.querySelectorAll('.checkbox.item:checked').length > 0)
                    {
                        container.querySelector('.bulk-options').classList.remove('hidden');
                    } else
                    {
                        container.querySelector('.bulk-options').classList.add('hidden');
                    }
                }, 200);

            },

            update_recurring(transaction_id, recurring, ele = null)
            {
                let scope = this;
                let formData = new FormData();
                formData.append('recurring', recurring);
                formData.append('transaction_id', transaction_id);
                axios.post('/company_accounts/update_recurring', formData)
                    .then(function (response)
                    {
                        notify('success', 'Recurring Successfully Updated');
                        if (!response.data.success)
                        {
                            scope.show_related_transactions = true;
                            scope.$refs.related_transactions_div.innerHTML = response.data;
                        }
                        setTimeout(function ()
                        {
                            scope.update_table_data();
                            scope.accounts_get_accounts();
                        }, 500);
                    })
                    .catch(function (error)
                    {
                    });
            },

            edit_account_name(internal_name, account_id, ele)
            {
                let scope = this;

                let formData = new FormData();
                formData.append('internal_name', internal_name);
                formData.append('account_id', account_id);

                axios.post('/company_accounts/edit_account_name', formData)
                    .then(function (response)
                    {
                        notify('success', 'Name Successfully Updated');
                        if (internal_name == '')
                        {
                            ele.innerText = ele.getAttribute('data-default');
                        } else
                        {
                            ele.innerText = internal_name;
                        }
                    })
                    .catch(function (error)
                    {
                    });
            },
            all_transactions(account_id)
            {
                this.get_transactions_data('all_transactions', account_id);
            },
            no_user(account_id)
            {
                this.get_transactions_data('no_user', account_id);
            },
            no_category(account_id)
            {
                this.get_transactions_data('no_category', account_id);
            },
            rejected(account_id)
            {
                this.get_transactions_data('rejected', account_id);
            },
            resolve_transaction(transaction_id, ele)
            {
                let scope = this;
                let formData = new FormData();
                formData.append('transaction_id', transaction_id);
                axios.post('/company_accounts/dashboard/resolve_transaction', formData)
                    .then(function (response)
                    {
                        notify('success', 'Transaction Successfully Resolved');

                        ele.closest('tr').classList.add('opacity-40');
                        ele.remove();
                    })
                    .catch(function (error)
                    {
                    });
            },
            get_transactions_data(type, account_id)
            {
                let scope = this;

                let statement_dates_interval = setInterval(function ()
                {
                    let statement_dates_input = document.getElementById('statement_dates_' + account_id);
                    if (statement_dates_input)
                    {
                        clearInterval(statement_dates_interval);
                        let statement_dates = statement_dates_input.value;
                        axios.get('/company_accounts/dashboard/get_' + type, {
                            params: {
                                account_id: account_id,
                                statement_dates: statement_dates
                            }
                        })
                            .then(function (response)
                            {
                                document.getElementById(type + '_div_' + account_id).innerHTML = response.data;
                                let count_interval = setInterval(function ()
                                {
                                    let source = document.getElementById(type + '_count_data_' + account_id);
                                    let target = document.getElementById(type + '_count_' + account_id);
                                    if (target && source)
                                    {
                                        clearInterval(count_interval);
                                        target.innerText = source.value;
                                        if (type != 'all_transactions')
                                        {
                                            scope.update_count_badge(target, source.value);
                                        }
                                    }
                                }, 200);
                            })
                            .catch(function (error)
                            {
                            });
                    }
                });

            },
            update_count_badge(target, value)
            {
                value = parseInt(value);
                target.classList.remove('text-red-700', 'bg-red-100', 'border-red-300', 'text-green-700', 'bg-green-50', 'border-green-300');
                if (value == 0)
                {
                    target.classList.add('text-green-700', 'bg-green-50', 'border', 'border-green-300');
                } else if (value > 0)
                {
                    target.classList.add('text-red-700', 'bg-red-100', 'border', 'border-red-300');
                }
            },
            update_counts(account_id = null)
            {
                let scope = this;
                account_id = account_id ? account_id : scope.active_account;
                let dates = null;
                if (document.querySelector('#statement_dates_' + account_id))
                {
                    dates = document.querySelector('#statement_dates_' + account_id).value;
                }

                if (!dates)
                {
                    setTimeout(function ()
                    {
                        scope.update_counts();
                    }, 2000);
                    return;
                }

                axios.get('/company_accounts/dashboard/update_counts', {
                    params: {
                        account_id: account_id,
                        statement_dates: dates
                    },
                })
                    .then(function (response)
                    {
                        if (response.data.status == 'error')
                        {
                            notify('error', response.data.message);
                            return;
                        }
                        document.querySelectorAll('.no-user-' + account_id).forEach(function (el)
                        {
                            el.innerText = response.data.no_user;
                            scope.update_count_badge(el, response.data.no_user);
                        });
                        document.querySelectorAll('.no-category-' + account_id).forEach(function (el)
                        {
                            el.innerText = response.data.no_category;
                            scope.update_count_badge(el, response.data.no_category);
                        });
                        document.querySelectorAll('.rejected-' + account_id).forEach(function (el)
                        {
                            el.innerText = response.data.rejected;
                            scope.update_count_badge(el, response.data.rejected);
                        });

                    })
                    .catch(function (error)
                    {
                    });
            },

            update_category_and_user(transaction_ids, category_id, user_id, ele)
            {
                let scope = this;
                scope.update_category(transaction_ids, category_id, ele, false);
                setTimeout(function ()
                {
                    scope.update_user(transaction_ids, user_id, ele);
                }, 500);
            },

            update_category(transaction_ids, category_id, ele, run_updates = true)
            {
                let scope = this;
                let container = ele.closest('table');
                if (category_id != 'ignore')
                {
                    if (transaction_ids.length == 0)
                    {
                        container.querySelectorAll('.checkbox.item:checked').forEach(function (checkbox)
                        {
                            transaction_ids.push(checkbox.value);
                        });
                    }
                    let formData = new FormData();
                    formData.append('transaction_ids', transaction_ids);
                    formData.append('category_id', category_id);
                    axios.post('/company_accounts/update_category', formData)
                        .then(function (response)
                        {
                            notify('success', 'Category Successfully Updated');
                            if (!response.data.success)
                            {
                                scope.show_related_transactions = true;
                                scope.$refs.related_transactions_div.innerHTML = response.data;
                            }


                            setTimeout(function ()
                            {
                                // scope.no_category(scope.active_account);
                                scope.get_transactions_data('all_transactions', scope.active_account);
                                if (run_updates === true)
                                {
                                    scope.update_counts();
                                }
                            }, 1000);

                        })
                        .catch(function (error)
                        {
                        });
                }
            },
            update_user(transaction_ids, user_id, ele = null)
            {
                if (user_id != 'ignore')
                {
                    let scope = this;
                    if (transaction_ids.length == 0)
                    {
                        document.querySelectorAll('.checkbox.item:checked').forEach(function (checkbox)
                        {
                            transaction_ids.push(checkbox.value);
                        });
                    }
                    let formData = new FormData();
                    formData.append('user_id', user_id);
                    formData.append('transaction_ids', transaction_ids);
                    axios.post('/company_accounts/update_user', formData)
                        .then(function (response)
                        {
                            notify('success', 'User Successfully Updated');

                            setTimeout(function ()
                            {
                                // scope.no_user(scope.active_account);
                                scope.get_transactions_data('all_transactions', scope.active_account);
                                // scope.update_table_data();
                                scope.update_counts();
                            }, 1000);

                            if (response.data.match(/<form/))
                            {
                                scope.show_related_transactions = true;
                                scope.$refs.related_transactions_div.innerHTML = response.data;
                            }

                        })
                        .catch(function (error)
                        {
                        });
                }
            },

            view_transaction(ele, transaction_id, disabled = false)
            {
                let scope = this;
                let transactions_div = scope.$refs.view_transaction_div;
                scope.show_view_transaction = true;

                document.querySelectorAll('.active-row').forEach(function (tr)
                {
                    tr.classList.remove('active-row', 'bg-gray-100');
                });
                ele.closest('tr').classList.add('bg-gray-100', 'active-row');

                axios.get('/company_accounts/view_transaction', { params: { transaction_id: transaction_id, disabled: disabled } })
                    .then(function (response)
                    {
                        transactions_div.innerHTML = response.data;
                        resizable_textarea(document.querySelector('.resizable'));
                    })
                    .catch(function (error)
                    {
                    });
            },
            update_transactions(ele)
            {
                let scope = this;
                let form = scope.$refs.related_transactions_form;
                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Saving ... ');
                scope.show_related_transactions = false;
                let formData = new FormData(form);
                axios.post('/company_accounts/update_transactions', formData)
                    .then(function (response)
                    {
                        ele.innerHTML = button_html;
                        notify('success', 'Transactions Successfully Updated');
                        setTimeout(function ()
                        {
                            scope.update_table_data();
                        }, 500);
                    })
                    .catch(function (error)
                    {
                    });
            },
            update_notes(ele, transaction_id, notes)
            {
                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Saving ... ');
                let formData = new FormData();
                formData.append('transaction_id', transaction_id);
                formData.append('notes', notes);
                axios.post('/company_accounts/update_notes', formData)
                    .then(function (response)
                    {
                        ele.innerHTML = button_html;
                        notify('success', 'Notes Successfully Updated');
                    })
                    .catch(function (error)
                    {
                    });
            },
            update_table_data(ele = null)
            {
                setTimeout(function ()
                {
                    let container = null;
                    if (ele)
                    {
                        container = ele.closest('table');
                    }
                    if (!container)
                    {
                        container = document;
                    }
                    container.querySelectorAll('.transaction-row').forEach(function (row)
                    {
                        let transaction_id = row.getAttribute('data-id');
                        let category_id = row.querySelector('.select-category');
                        let user_id = row.querySelector('.select-user');
                        let internal_name = row.querySelector('.internal-name');
                        let recurring = row.querySelector('.recurring');
                        axios.get('/company_accounts/update_table_data', {
                            params: {
                                transaction_id: transaction_id
                            },
                        })
                            .then(function (response)
                            {
                                if (category_id.classList.contains('form-element'))
                                {
                                    if (response.data.category_id)
                                    {
                                        category_id.value = response.data.category_id;
                                    }
                                    if (response.data.user_id)
                                    {
                                        user_id.value = response.data.user_id;
                                    }
                                } else
                                {
                                    category_id.innerText = response.data.category;
                                    user_id.innerText = response.data.user;
                                }
                                internal_name.innerText = response.data.internal_name;
                                recurring.innerText = response.data.recurring == '1' ? 'Yes' : 'No';
                            })
                            .catch(function (error)
                            {
                            });
                    });
                    // setTimeout(function ()
                    // {
                    //     get_no_user_category_count();
                    // }, 2000);
                }, 200);
            },
            edit_transaction_name(internal_name, transaction_id, ele)
            {
                let scope = this;
                let formData = new FormData();
                formData.append('internal_name', internal_name);
                formData.append('transaction_id', transaction_id);
                axios.post('/company_accounts/edit_transaction_name', formData)
                    .then(function (response)
                    {
                        notify('success', 'Name Successfully Updated');
                        ele.innerText = internal_name;
                        if (response.data.match(/<form/))
                        {
                            scope.show_related_transactions = true;
                            scope.$refs.related_transactions_div.innerHTML = response.data;
                        }
                        setTimeout(function ()
                        {
                            scope.update_table_data();
                        }, 500);
                    })
                    .catch(function (error)
                    {
                    });
            },
            open_email_transaction(ele, transaction_ids = null)
            {
                let scope = this;
                let container = ele.closest('table');
                let email_transaction_div = scope.$refs.email_transaction_div;
                if (!transaction_ids)
                {
                    transaction_ids = [];
                    container.querySelectorAll('.checkbox.item:checked').forEach(function (checkbox)
                    {
                        transaction_ids.push(checkbox.value);
                    });
                }
                axios.get('/company_accounts/open_email_transaction', { params: { transaction_ids: transaction_ids } })
                    .then(function (response)
                    {
                        scope.show_email_transaction = true;
                        email_transaction_div.innerHTML = response.data;
                        scope.message_editor();
                    })
                    .catch(function (error)
                    {
                    });
            },
            send_email_transactions(ele)
            {
                let scope = this;
                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Sending Email ... ');
                remove_form_errors();
                let form = document.getElementById('email_transactions_form');
                let formData = new FormData(form);
                let message = tinymce.activeEditor.getContent()
                formData.append('message', message);
                axios.post('/company_accounts/send_email_transactions', formData)
                    .then(function (response)
                    {
                        ele.innerHTML = button_html;
                        notify('success', 'Email Successfully Sent');
                        scope.show_email_transaction = false;
                    })
                    .catch(function (error)
                    {
                        display_errors(error, ele, button_html);
                    });
            },
            /* Accounts */
            create_link_token(access_token = null)
            {
                let scope = this;
                axios.get('/company_accounts/create_link_token', { params: { access_token: access_token } })
                    .then(function (response)
                    {
                        let link_token = response.data.link_token;
                        scope.link(link_token, access_token);
                    })
                    .catch(function (error)
                    {
                    });
            },
            link(link_token, access_token)
            {
                let scope = this;
                const handler = Plaid.create({
                    token: link_token,
                    onSuccess: (public_token, metadata) =>
                    {
                        let institution_id = metadata.institution.institution_id;
                        let accounts = metadata.accounts;
                        accounts = JSON.stringify(accounts);
                        axios.get('/company_accounts/check_if_institution_exists', {
                            params: {
                                institution_id: institution_id,
                                accounts: accounts
                            },
                        })
                            .then(function (response)
                            {
                                if (response.data.status == 'error' && !access_token)
                                {
                                    notify('error', 'Institution already exists');
                                    let access_token = response.data.access_token;
                                    scope.show_add_to_existing_item = true;
                                    document.getElementById('add_to_existing_button').addEventListener('click', function ()
                                    {
                                        scope.create_link_token(access_token);
                                        scope.show_add_to_existing_item = false;
                                    });
                                } else
                                {
                                    show_loading();
                                    axios.get('/company_accounts/exchange_token', {
                                        params: {
                                            public_token: public_token
                                        },
                                    })
                                        .then(function (response)
                                        {
                                            // scope.get_issuer_and_accounts(response.data.access_token);
                                            scope.select_accounts(response.data.access_token);
                                        })
                                        .catch(function (error)
                                        {
                                        });
                                }
                            })
                            .catch(function (error)
                            {
                            });
                    },
                    onLoad: () =>
                    {
                    },
                    onExit: (err, metadata) =>
                    {
                        console.log('error = ' + err);
                    },
                    onEvent: (eventName, metadata) =>
                    {
                    },
                });
                handler.open();
            },
            select_accounts(access_token)
            {
                let scope = this;
                axios.get('/company_accounts/select_accounts', {
                    params: {
                        access_token: access_token
                    },
                })
                    .then(function (response)
                    {
                        setTimeout(function ()
                        {
                            scope.$refs.select_accounts_div.innerHTML = response.data;
                            scope.show_select_accounts = true;
                            hide_loading();
                        }, 1000);
                    })
                    .catch(function (error)
                    {
                    });
            },
            save_accounts(ele)
            {
                if (document.querySelectorAll('[name="accounts[]"]:checked').length > 0)
                {
                    let scope = this;
                    let button_html = ele.innerHTML;
                    show_loading_button(ele, 'Saving Accounts ... ');
                    show_loading();
                    let form = scope.$refs.accounts_form;
                    let formData = new FormData(form);
                    axios.post('/company_accounts/save_accounts', formData)
                        .then(function (response)
                        {
                            ele.innerHTML = button_html;
                            notify('success', 'Accounts Successfully Added');
                            scope.show_select_accounts = false;
                            scope.accounts_get_accounts();
                            hide_loading();
                        })
                        .catch(function (error)
                        {
                        });
                } else
                {
                    notify('error', 'Please select at least one account');
                }
            },
            edit_account(account_id, company, description, recurring)
            {
                let scope = this;
                scope.show_edit_account = true;
                scope.$refs.account_id.value = account_id;
                scope.$refs.company.value = company;
                scope.$refs.description.value = description;
                let checked = recurring == 1 ? true : false;
                scope.$refs.recurring.checked = checked;
            },
            save_account(ele)
            {
                let scope = this;
                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Saving Details ... ');
                let form = scope.$refs.edit_account_form;
                let formData = new FormData(form);
                axios.post('/company_accounts/save_account', formData)
                    .then(function (response)
                    {
                        ele.innerHTML = button_html;
                        notify('success', 'Credit Card Successfully Updated');
                        scope.show_edit_account = false;
                        scope.accounts_get_accounts();
                    })
                    .catch(function (error)
                    {
                    });
            },
            edit_users(account_id)
            {
                let scope = this;
                axios.get('/company_accounts/edit_users', { params: { account_id: account_id } })
                    .then(function (response)
                    {
                        scope.show_users = true;
                        scope.$refs.users_div.innerHTML = response.data;
                        setTimeout(function ()
                        {
                            scope.$refs.users_account_id.value = account_id;
                        }, 300);
                    })
                    .catch(function (error)
                    {
                    });
            },
            save_users(ele)
            {
                let scope = this;
                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Saving Users ... ');
                let form = scope.$refs.users_form;
                let formData = new FormData(form);
                axios.post('/company_accounts/save_users', formData)
                    .then(function (response)
                    {
                        ele.innerHTML = button_html;
                        notify('success', 'Credit Card Users Successfully Updated');
                        scope.show_users = false;
                        scope.accounts_get_accounts();
                    })
                    .catch(function (error)
                    {
                    });
            },
            show_active(active)
            {
                this.accounts_get_accounts(active);
            },
            set_active(account_id, active)
            {
                let scope = this;
                axios.get('/company_accounts/set_active', {
                    params: {
                        account_id: account_id,
                        active: active
                    },
                })
                    .then(function (response)
                    {
                        let active = scope.$refs.show_active.value;
                        scope.accounts_get_accounts(active);
                    })
                    .catch(function (error)
                    {
                    });
            },
            /* Settings */
            get_categories()
            {
                let scope = this;
                axios.get('/company_accounts/settings/get_categories')
                    .then(function (response)
                    {
                        scope.$refs.categories_div.innerHTML = response.data;
                    })
                    .catch(function (error)
                    {
                    });
            },
            save_category(ele)
            {
                let scope = this;
                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Saving Category ... ');
                let form = scope.$refs.add_category_form;
                let formData = new FormData(form);
                axios.post('/company_accounts/settings/save_category', formData)
                    .then(function (response)
                    {
                        ele.innerHTML = button_html;
                        notify('success', 'Category Successfully Added');
                        scope.get_categories();
                        scope.add_category = false;
                    })
                    .catch(function (error)
                    {
                    });
            },
            save_edit_category(ele)
            {
                let scope = this;
                let form = scope.$refs.edit_category_form;
                let formData = new FormData(form);
                axios.post('/company_accounts/settings/save_edit_category', formData)
                    .then(function (response)
                    {
                        notify('success', 'Category Successfully Updated');
                        scope.get_categories();
                    })
                    .catch(function (error)
                    {
                    });
            },
            delete_category(category_id)
            {
                let scope = this;
                if (confirm('Are you sure you want to delete this category?'))
                {
                    axios.get('/company_accounts/settings/delete_category', { params: { category_id: category_id } })
                        .then(function (response)
                        {
                            notify('success', 'Category Successfully Deleted');
                            scope.get_categories();
                        })
                        .catch(function (error)
                        {
                        });
                }
            },
            get_bill_due_alert()
            {
                let scope = this;
                axios.get('/company_accounts/settings/get_bill_due_alerts')
                    .then(function (response)
                    {
                        scope.$refs.bill_due_alerts_div.innerHTML = response.data;
                    })
                    .catch(function (error)
                    {
                    });
            },
            save_bill_due_user(ele, user_id)
            {
                let scope = this;
                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Saving ... ');
                let formData = new FormData();
                formData.append('user_id', user_id);
                axios.post('/company_accounts/settings/save_bill_due_user', formData)
                    .then(function (response)
                    {
                        ele.innerHTML = button_html;
                        scope.add_bill_due_user = false;
                        notify('success', 'User Successfully Added');
                        scope.get_bill_due_alert();
                    })
                    .catch(function (error)
                    {
                    });
            },
            delete_bill_due_user(user_id)
            {
                let scope = this;
                if (confirm('Are you sure you want to delete this user?'))
                {
                    axios.get('/company_accounts/settings/delete_bill_due_user', { params: { user_id: user_id } })
                        .then(function (response)
                        {
                            notify('success', 'User Successfully Deleted');
                            scope.get_bill_due_alert();
                        })
                        .catch(function (error)
                        {
                        });
                }
            },
            message_editor()
            {
                let ele = '.text-editor';
                let options = {
                    selector: ele,
                    min_height: 400,
                    height: 400,
                    statusbar: false,
                    menubar: false,
                    plugins: [
                        'advlist', 'autolink', 'lists', 'image', 'visualblocks', 'code', 'fullscreen', 'media', 'table', 'autoresize'
                    ],
                    toolbar: 'undo redo bold italic | forecolor backcolor | image | bullist numlist outdent indent',
                    table_toolbar: 'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
                    relative_urls: false,
                    document_base_url: location.hostname,
                }
                text_editor(options);
            },
            /* Reports */
            get_monthly_usage_reports()
            {
                this.get_monthly_usage_report('Card');
                this.get_monthly_usage_report('User');
                this.get_monthly_usage_report('Category');
            },
            get_monthly_usage_report(report_type)
            {
                let scope = this;
                let year = this.report_year;
                let report_type_lower = report_type.toLowerCase();
                axios.get('/company_accounts/reports/monthly_usage_by_' + report_type_lower, { params: { year: year } })
                    .then(function (response)
                    {
                        let transactions = response.data.transactions_data;
                        let month_names = response.data.month_names;
                        let transactions_data = response.data.transactions_data;
                        const monthly_usage_by_card_chart = Highcharts.chart('monthly_usage_by_' + report_type_lower, {
                            chart: {
                                type: scope.chart_type,
                            },
                            title: {
                                text: 'Transactions By ' + report_type
                            },
                            xAxis: {
                                categories: month_names,
                                labels: {
                                    formatter: function ()
                                    {
                                        return this.value + '<br>' + scope.report_year;
                                    }
                                },
                            },
                            yAxis: {
                                title: {
                                    text: 'Total Spent'
                                },
                                labels: {
                                    formatter: function ()
                                    {
                                        return '$' + format_number(this.value)
                                    }
                                },
                            },
                            plotOptions: {
                                series: {
                                    cursor: 'pointer',
                                    point: {
                                        events: {
                                            click: function ()
                                            {
                                                scope.get_transactions_reports(month_names[this.x], scope.report_year, report_type_lower, this.series.userOptions.account_id, this.series.userOptions.user_id, this.series.userOptions.category_id);
                                            }
                                        }
                                    }
                                }
                            },
                            tooltip: {
                                pointFormatter: function ()
                                {
                                    var value = '$' + format_number(this.y);
                                    var name = this.series.name;
                                    return value + '<br>' + name;
                                },
                                borderWidth: 2,
                                borderRadius: 10,
                                padding: 10,
                                style: {
                                    fontSize: '16',
                                    color: 'rgb(107 114 128)',
                                }
                            },
                            legend: {
                                layout: 'vertical',
                                align: 'right',
                                verticalAlign: 'middle',
                                itemStyle: {
                                    fontSize: '14px'
                                }
                            },
                            responsive: {
                                rules: [{
                                    condition: {
                                        maxWidth: 500
                                    },
                                    chartOptions: {
                                        legend: {
                                            layout: 'horizontal',
                                            align: 'center',
                                            verticalAlign: 'bottom'
                                        }
                                    }
                                }]
                            },
                            series: transactions_data
                        });
                    })
                    .catch(function (error)
                    {
                    });
            },
            get_transactions_reports(month, year, report_type, account_id, user_id, category_id)
            {
                let scope = this;
                axios.get('/company_accounts/reports/reports_get_transactions', { params: { month: month, year: year, report_type: report_type, account_id: account_id, user_id: user_id, category_id: category_id } })
                    .then(function (response)
                    {
                        document.getElementById('table_data').innerHTML = response.data;
                    })
                    .catch(function (error)
                    {
                    });
            },
            update_chart_type(type)
            {
                this.chart_type = type;
                this.get_monthly_usage_reports();
            },
            update_year(year)
            {
                this.report_year = year;
                this.get_monthly_usage_reports();
            },
            get_quick_books_data(start_date_input = null, end_date_input = null, account_id = null)
            {
                let scope = this;
                let quick_books_account = null;

                if (document.querySelector('#quick_books_account'))
                {
                    quick_books_account = document.querySelector('#quick_books_account').value;
                }

                if (account_id)
                {
                    scope.all_account_ids = [account_id];
                }

                scope.all_account_ids.forEach(function (account_id)
                {
                    let container = document.getElementById('quick_books_data_container_' + account_id);

                    let dates = null;
                    if (document.querySelector('#statement_dates_' + account_id))
                    {
                        dates = document.querySelector('#statement_dates_' + account_id).value;
                    }

                    let params = {
                        statement_dates: dates,
                        account_id: account_id,
                        quick_books_account: quick_books_account,
                    };
                    if (start_date_input)
                    {
                        params.start_date = start_date_input.value;
                        params.end_date = end_date_input.value;
                    }

                    axios.get('/company_accounts/reports/get_quick_books_data', {
                        params: params,
                    })
                        .then(function (response)
                        {
                            let div = document.querySelector('#quick_books_data_' + account_id);
                            div.innerHTML = response.data;
                            setTimeout(function ()
                            {

                                let dates = div.querySelector('.statement-dates-select').value;
                                let start_date_input = container.querySelector('.quick-books-start-date');
                                let end_date_input = container.querySelector('.quick-books-end-date');
                                if (start_date_input.value == '')
                                {
                                    start_date_input.value = dates.split(',')[0];
                                    end_date_input.value = dates.split(',')[1];
                                } else
                                {
                                    // add new option to dates select
                                    let new_option = document.createElement('option');
                                
                                    new_option.value = start_date_input.value + ',' + end_date_input.value;
                                    new_option.innerHTML = start_date_input.value + ' - ' + end_date_input.value;
                                    div.querySelector('.statement-dates-select').appendChild(new_option);
                                    div.querySelector('.statement-dates-select').value = start_date_input.value + ',' + end_date_input.value;
                                }
                            }, 1000);
                        })
                        .catch(function (error)
                        {
                            console.log(error);
                        });
                });
            },

            get_quick_books_vendors(ele, category_id, account_id, start_date, end_date)
            {
                let scope = this;
                axios.get('/company_accounts/reports/get_quick_books_vendors', {
                    params: {
                        category_id: category_id,
                        account_id: account_id,
                        start_date: start_date,
                        end_date: end_date
                    },
                })
                    .then(function (response)
                    {

                        let vendors = response.data.vendors;
                        vendors = vendors.join(', ');
                        scope.show_vendors = true;

                        document.getElementById('quick_books_vendors_div_' + account_id).innerHTML = vendors;

                        document.getElementById('quick_books_vendors_div_' + account_id).parentNode.querySelector('.copy-me-div').querySelector('input').value = vendors;

                        document.querySelectorAll('.active-tr').forEach(function (tr)
                        {
                            tr.classList.remove('active-tr', 'bg-gray-100');
                        });
                        ele.closest('tr').classList.add('active-tr', 'bg-gray-100');

                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
            },

            print_reports(divs)
            {
                if (divs != 'all')
                {
                    divs = [divs];
                } else
                {
                    divs = [];
                    this.all_account_ids.forEach(function (account_id)
                    {
                        divs.push(document.querySelector('#quick_books_data_' + account_id));
                    });
                }
                let a = window.open('', '', 'height=700, width=1000');
                a.document.write(`
                <html><head>
                <style type="text/css">
                * {
                    font-family: Arial, sans-serif;
                }
                table th:first-child {
                    text-align: left;
                }
                table td {
                    border:1px solid #ccc;
                    padding:0.5em;
                }
                table td:nth-child(2) {
                    text-align: right;
                }
                .statement-dates-select, label, .no-print {
                    display: none;
                }
                </style>
                </head><body>`);
                divs.forEach(function (div)
                {
                    // var table = div.querySelector('.table-data');
                    var div_contents = div.innerHTML;
                    var account_name = div.getAttribute('data-account-name');
                    var mask = div.getAttribute('data-account-mask');
                    a.document.write('<div style="font-size: 16px; font-weight: bold; margin-bottom: 35px; margin-top: 35px">' + mask + ' | ' + account_name + '</div>');
                    a.document.write(div_contents);
                    a.document.write('<div style="height: 15px; border-bottom: 1px solid #ccc; margin-bottom: 15px;"></div>');
                });
                a.document.write('</body></html>');
                a.document.close();
                a.print();
                a.close();
            },
            email_reports(ele)
            {
                let scope = this;
                let divs = scope.divs_to_email;
                let divs_original = divs;
                let send_reports_to_id = scope.$refs.send_reports_to_id.value;
                let button_html = ele.innerHTML;
                let dates = document.querySelector('#statement_dates_' + divs).value;
                let start = dates.split(',')[0];
                let end = dates.split(',')[1];

                if (send_reports_to_id == '')
                {
                    notify('error', 'Please Enter Email Address');
                    return;
                }
                show_loading_button(ele, 'Sending Email ... ');
                // if (divs != 'all')
                // {
                divs = [document.querySelector('#quick_books_data_' + divs)];
                // } else
                // {
                //     divs = [];
                //     this.all_account_ids.forEach(function (account_id)
                //     {
                //         divs.push(document.querySelector('#quick_books_data_' + account_id));
                //     });
                // }
                let html = `
                <html><head>
                <meta http-equiv="Content-Type" content="text/html charset=UTF-8" />
                <style type="text/css">
                .table-data * {
                    font-family: Arial, sans-serif;
                }
                .table-data table th:first-child {
                    text-align: left !important;
                }
                .table-data table td {
                    border:1px solid #ccc;
                    padding:0.5em;
                }
                .table-data table td:nth-child(2) {
                    text-align: right !important;
                }
                .statement-dates-select, label {
                    display: none;
                }
                </style>
                </head><body>
                <table style="width: 100%; margin-bottom: 15px;" width="100%">
                <tr>
                <td style="font-size: 20px; font-weight: bold; width: 50%;">Quick Books Reports</td>
                <td align="right" style="width: 50%;"><a href="`+ app_url + `/company_accounts/reports/print_reports/` + divs_original + `/` + start + `/` + end + `/` + send_reports_to_id + `" style="padding: 8px 15px; background: #225A96; color: white; border-radius: 5px; text-decoration: none;">Print Report</a></td>
                </tr>
                </table>
                `;
                divs.forEach(function (div)
                {
                    var div_contents = div.innerHTML;
                    var account_name = div.getAttribute('data-account-name');
                    var mask = div.getAttribute('data-account-mask');

                    let temp_div = document.createElement('div');
                    temp_div.innerHTML = div_contents;
                    temp_div.querySelectorAll('.no-print').forEach(function (el)
                    {
                        el.remove();
                    });
                    div_contents = temp_div.innerHTML;
                    html = html + '<div style="font-size: 16px; font-weight: bold; margin-bottom: 15px; margin-top: 35px">' + mask + ' | ' + account_name + '</div>';
                    html = html + div_contents;
                    html = html + '<div style="height: 30px;"></div>';
                });
                html = html + '</body></html>';
                let formData = new FormData();
                formData.append('html', html);
                formData.append('send_reports_to_id', send_reports_to_id);
                axios.post('/company_accounts/reports/email_reports', formData)
                    .then(function (response)
                    {
                        notify('success', 'Email Sent Successfully');
                        scope.show_email_reports_div = false;
                        ele.innerHTML = button_html;
                    })
                    .catch(function (error)
                    {
                    });
            },
        }
    }
}