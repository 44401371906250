window.home_buyer_leads = function ()
{

    return {

        init()
        {
            this.get_home_buyer_leads();
        },

        get_home_buyer_leads()
        {
            let scope = this;
            axios.get('/marketing/leads/get_home_buyer_leads')
                .then(response =>
                {
                    scope.$refs.available.innerHTML = response.data.available;
                    scope.$refs.left_in_queue.innerHTML = response.data.left_in_queue;
                    scope.$refs.contacted_eight_plus_times.innerHTML = response.data.contacted_eight_plus_times;
                    scope.$refs.contacted_in_queue.innerHTML = response.data.contacted_in_queue;
                    scope.$refs.added_to_queue.innerHTML = response.data.added_to_queue;
                    scope.$refs.dead.innerHTML = response.data.dead;
                    scope.$refs.transferred.innerHTML = response.data.transferred;
                });
        },

        add_leads_to_queue(ele)
        {
            let scope = this;
            let count = scope.$refs.count.value;
            let button_html = ele.innerHTML;
            show_loading_button(ele, 'Adding ... ');

            axios.get('/marketing/leads/add_leads_to_queue', {
                params: {
                    count: count
                },
            })
            .then(function (response) {
                ele.innerHTML = button_html;
                scope.get_home_buyer_leads();
                notify('success', 'Leads added to queue');
            })
            .catch(function (error) {
                console.log(error);
            });
        },
    }

}