if (document.URL.match(/leads/))
{

    window.agents = function ()
    {

        return {
            active_tab_marketing_agents: parseInt(localStorage.getItem('active_tab_marketing_agents')) || 0,
            show_view_list: false,
            location_type: 'location', // location, distance
            manual_search_id: null,
            show_download_automated_list_modal: false,
            show_download_manual_list_modal: false,
            show_download_results: false,
            nutshell_list_type: 'all',
            nutshell_list_order_by: 'production',
            states: [],
            state_values: [
                { id: 1, name: 'MD', disabled: false },
                { id: 2, name: 'VA', disabled: false },
                { id: 3, name: 'DC', disabled: false },
                { id: 4, name: 'DE', disabled: false },
                { id: 5, name: 'PA', disabled: false },
            ],
            get states_label()
            {
                if (this.states.length === 0)
                {
                    this.$refs.listbox_button.disabled = true;
                    this.$refs.listbox_button.classList.add('cursor-not-allowed', 'opacity-50');
                    return 'Choose states...';
                }
                this.$refs.listbox_button.disabled = false;
                this.$refs.listbox_button.classList.remove('cursor-not-allowed', 'opacity-50');
                return this.states.length === 1 ? this.states[0].name : `${this.states.length} selected`;
            },
            counties: [],
            county_values: [],
            get counties_label()
            {
                if (this.counties.length === 0)
                {

                    return 'Choose counties...';
                }
                return this.counties.length === 1 ? this.counties[0].name : `${this.counties.length} selected`;
            },

            init()
            {
                this.get_counties(true);
            },

            show_download_automated_list(slug = null)
            {
                this.show_download_automated_list_modal = true;
                this.$refs.slug.value = slug;
            },

            download_automated_list(ele, download_type)
            {
                let scope = this;
                let button_html = ele.innerHTML;
                let button_text = download_type == 'download' ? 'Downloading ...' : 'Exporting ...';
                show_loading_button(ele, button_text);
                let slug = scope.$refs.slug.value;
                axios.get('/marketing/leads/agents/download_automated_list', {
                    params: {
                        slug: slug,
                        download_type: download_type,
                    },
                })
                    .then(function (response)
                    {

                        ele.innerHTML = button_html;
                        scope.show_download_automated_list_modal = false;

                        if (download_type == 'export')
                        {
                            scope.$refs.list_name.innerHTML = response.data.list_name;
                            scope.show_download_results = true;
                            notify('success','List successfully added to SendGrid');
                        } else if (download_type == 'download')
                        {
                            window.location = response.data.url;
                        }

                    })
                    .catch(function (error)
                    {
                        ele.innerHTML = button_html;
                    });
            },

            manual_search()
            {
                let scope = this;
                let miles = document.querySelector('input[name="miles"]').value || null;
                let location = document.querySelector('input[name="location"]').value || null;
                let office_name = document.querySelector('input[name="office_name"]').value || null;
                let producers = document.querySelector('input[name="producers"]:checked').value || null;

                scope.$refs.agents_count.innerHTML = '<span class="text-base font-normal">Getting Count <i class="fas fa-spinner fa-spin ml-2"></i></span>';

                document.querySelectorAll('.results-only').forEach(function (ele)
                {
                    ele.classList.remove('hidden');
                });

                let new_id = Math.random();
                scope.manual_search_id = new_id;
                setTimeout(function ()
                {
                    if (new_id != scope.manual_search_id)
                    {
                        return;
                    }


                    axios.get('/marketing/leads/agents/manual_search', {
                        params: {
                            location_type: scope.location_type,
                            states: scope.states,
                            counties: scope.counties,
                            miles: miles,
                            location: location,
                            office_name: office_name,
                            producers: producers,
                        },
                    })
                        .then(function (response)
                        {
                            if (new_id != scope.manual_search_id)
                            {
                                return;
                            }
                            scope.$refs.agents_count.innerHTML = response.data.agents_count;
                        })
                        .catch(function (error)
                        {
                            console.log(error);
                        });
                }, 2000);





            },

            get_counties(initial_call = false)
            {
                let scope = this;
                let states = this.states;

                axios.get('/marketing/leads/agents/get_counties', {
                    params: {
                        states: states,
                    },
                })
                    .then(function (response)
                    {
                        if (response.data.counties)
                        {
                            scope.county_values = response.data.counties;
                        }

                    });


            },

            view_list(ele)
            {
                let scope = this;
                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Loading ...');
                axios.get('/marketing/leads/agents/view_list')
                    .then(function (response)
                    {
                        scope.$refs.view_list_div.innerHTML = response.data;
                        scope.show_view_list = true;
                        ele.innerHTML = button_html;
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
            },

            show_download_manual_list()
            {
                this.show_download_manual_list_modal = true;
            },

            download_manual_list(ele, download_type)
            {
                let scope = this;
                let button_html = ele.innerHTML;

                let button_text = download_type == 'download' ? 'Downloading ...' : 'Exporting ...';
                show_loading_button(ele, button_text);
                axios.get('/marketing/leads/agents/download_manual_list', {
                    params: {
                        download_type: download_type,
                    },
                })
                    .then(function (response)
                    {
                        scope.show_download_manual_list_modal = false;

                        ele.innerHTML = button_html;
                        if (download_type == 'export')
                        {
                            scope.$refs.list_name.innerHTML = response.data.list_name;
                            scope.show_download_results = true;
                            notify('success', 'List successfully added to SendGrid');
                        } else if (download_type == 'download')
                        {
                            window.location = response.data.url;
                        }

                    })
                    .catch(function (error)
                    {
                        console.log(error);
                        ele.innerHTML = button_html;
                    });
            },

            /* Nutshell Leads */

            add_leads_to_nutshell(ele)
            {
                let scope = this;
                let amount_of_leads_to_add = scope.$refs.amount_of_leads_to_add.value;
                let assign_to_user_id = scope.$refs.assign_to_user_id.value;
                let nutshell_list_type = scope.nutshell_list_type;
                let nutshell_list_order_by = scope.nutshell_list_order_by;
                
                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Adding Leads... ');
                remove_form_errors();
                
                let formData = new FormData();
                    formData.append('amount_of_leads_to_add', amount_of_leads_to_add);
                    formData.append('assign_to_user_id', assign_to_user_id);
                    formData.append('nutshell_list_type', nutshell_list_type);
                    formData.append('nutshell_list_order_by', nutshell_list_order_by);
                
                axios.post('/marketing/leads/agents/add_leads_to_nutshell', formData)
                .then(function (response) {
                    ele.innerHTML = button_html;
                
                })
                .catch(function (error) {
                    display_errors(error, ele, button_html);
                    ele.innerHTML = button_html;
                });
            },

        }

    }

}