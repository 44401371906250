
if (document.URL.match(/in_house$/))
{

    window.in_house_employees = function ()
    {

        return {

            show_change_order: false,

            init()
            {
                this.sortable();
            },

            sortable: function ()
            {

                let scope = this;
                let sortable_div = scope.$refs.sortable_div;
                let sortable = Sortable.create(sortable_div, {
                    handle: ".employee-handle",  // Drag handle selector within list items
                    draggable: ".employees-item",  // Specifies which items inside the element should be draggable
                    chosenClass: "sortable-chosen",  // Class name for the chosen item
                    ghostClass: "sortable-ghost",  // Class name for the drop placeholder
                    dragClass: "sortable-drag",  // Class name for the dragging item

                    onEnd: function (evt)
                    {

                        scope.employees_update_order(sortable_div);

                    },

                });
            },

            employees_update_order(container)
            {

                let employees = [];
                container.querySelectorAll('.employees-item').forEach(function (employee, i)
                {
                    let data = {
                        id: employee.getAttribute('data-id'),
                        order: i
                    }
                    employees.push(data);
                });

                let formData = new FormData();
                formData.append('employees', JSON.stringify(employees));
                formData.append('type', 'in_house');
                axios.post('/employees/employees_update_order', formData)
                    .then(function (response)
                    {
                        notify('success', 'Reorder Successful');
                    })
                    .catch(function (error)
                    {
                    });

            },

            in_house_change_website_order()
            {
                let scope = this;
                scope.show_change_order = true;
            },

        }


    }


}
