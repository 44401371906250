if (document.URL.match(/(dev|com)\/dashboard/))
{

    window.dashboard = function (group)
    {

        return {

            group: group,
            active_stat: '',
            active_tab: localStorage.getItem('agent_dashboard_active_tab') || 0,
            show_view_pre_listing: false,
            show_image_preview: false,
            show_map: false,
            show_view_coming_soon_listing: false,

            init()
            {
                if (this.group === 'mortgage')
                {
                    this.get_tasks();
                } else if (this.group !== 'agent')
                {
                    this.get_license_stats();
                }

                if (this.group === 'agent')
                {
                    this.get_agent_seller_concessions_dashboard();
                    this.get_pre_listings('active', 'agent');
                    this.get_pre_listings('active', 'company');
                    this.get_coming_soon_dashboard();
                }
            },
            /* agents */
            get_agent_seller_concessions_dashboard()
            {
                let scope = this;
                axios.get('/agents/seller_concessions/get_agent_seller_concessions_dashboard', {
                    params: { full_details: 'yes' }
                })
                    .then(function (response)
                    {
                        scope.$refs.seller_concessions_div.innerHTML = response.data;
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
            },

            get_pre_listings(type, view)
            {
                let scope = this;

                axios.get('/agents/pre_listings/get_pre_listings/' + type + '/' + view)
                    .then(function (response)
                    {
                        if (view == 'agent')
                        {
                            scope.$refs.pre_listings_agents_div.innerHTML = response.data;
                        } else
                        {
                            scope.$refs.pre_listings_company_div.innerHTML = response.data;
                        }
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
            },

            edit_pre_listing(listing_id)
            {
                window.location.href = '/agents/pre_listings/pre_listings/edit/' + listing_id;
            },

            view_pre_listing(listing_id, ele)
            {
                let scope = this;
                let container = ele.closest('.pre-listing');
                axios.get('/agents/pre_listings/get_pre_listing_details/' + listing_id)
                    .then(function (response)
                    {
                        scope.$refs.view_pre_listing_div.innerHTML = response.data;
                        scope.show_view_pre_listing = true;
                    });

                if (document.querySelector('.pre-listing.active'))
                {
                    document.querySelector('.pre-listing.active').classList.remove('bg-gray-100');
                    document.querySelector('.pre-listing').classList.remove('active');
                }
                container.classList.add('bg-gray-100');
                container.classList.add('active');
            },

            load_image_preview(photo_location, title = null, bright_image = null)
            {
                let scope = this;
                scope.$refs.image_title_div.innerHTML = '';
                if (title)
                {
                    scope.$refs.image_title_div.innerHTML = title;
                }
                if (bright_image)
                {
                    scope.$refs.image_preview_div.innerHTML = '<img src="' + photo_location + '" class="max-h-[700px] rounded shadow" alt="">';
                } else
                {
                    scope.$refs.image_preview_div.innerHTML = '<img src="/storage/' + photo_location + '" class="max-h-[700px] rounded shadow" alt="">';
                }
                scope.show_image_preview = true;
            },

            view_map(address)
            {
                let scope = this;
                let src = scope.$refs.map_iframe.dataset.src;
                scope.$refs.map_iframe.src = src.replace('%%address%%', address);
                scope.show_map = true;
            },

            get_coming_soon_dashboard()
            {
                let scope = this;
                axios.get('/agents/coming_soon/get_coming_soon_dashboard')
                    .then(function (response)
                    {
                        scope.$refs.coming_soon_div.innerHTML = response.data;
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
            },

            get_coming_soon_details(listing_id, ele)
            {
                let scope = this;
                let container = ele.closest('.coming-soon-listing');
                scope.show_view_coming_soon_listing = true;
                scope.$refs.coming_soon_listing_div.innerHTML = `
                    <div class="flex justify-center items-center h-full">
                        <i class="fa-duotone fa-spinner fa-spin fa-6x text-sky-500"></i>
                    </div>
                `;
                axios.get('/agents/coming_soon/get_coming_soon_details/' + listing_id)
                    .then(function (response)
                    {
                        scope.$refs.coming_soon_listing_div.innerHTML = response.data;

                    });

                if (document.querySelector('.coming-soon-listing.active'))
                {
                    document.querySelector('.coming-soon-listing.active').classList.remove('bg-gray-100');
                    document.querySelector('.coming-soon-listing').classList.remove('active');
                }
                container.classList.add('bg-gray-100');
                container.classList.add('active');
            },

            /* end agents */

            get_tasks()
            {
                let scope = this;
                axios.get('/dashboard/get_tasks')
                    .then(function (response)
                    {
                        scope.$refs.tasks_div.innerHTML = response.data;
                    })
                    .catch(function (error)
                    {
                    });
            },

            get_license_stats()
            {
                let scope = this;
                let page = 'company_licenses';
                if (document.URL.match(/(dev|com)\/dashboard/))
                {
                    page = 'dashboard';
                }
                axios.get('/licenses/get_license_stats', { params: { page: page } })
                    .then(function (response)
                    {
                        if (response.data)
                        {
                            scope.$refs.license_stats_div.innerHTML = response.data;
                            // scope.show_license_stats = true;
                        }
                    })
                    .catch(function (error)
                    {

                    });
            },

        }

    }

}
