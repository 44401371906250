window.coming_soon = function ()
{

    return {

        show_view_coming_soon_listing: false,
        show_image_preview: false,
        show_map: false,

        init()
        {
            this.get_coming_soon();
        },

        get_coming_soon(page = 1, per_page = 10, search_value = null, city = null)
        {
            let scope = this;
            axios.get('/agents/coming_soon/get_coming_soon/', {
                params: {
                    page: page,
                    per_page: per_page,
                    search_value: search_value,
                    city: city
                }
            })
                .then(function (response)
                {
                    scope.$refs.coming_soon_div.innerHTML = response.data;
                    scope.pagination_links();
                });
        },

        get_coming_soon_details(listing_id, ele)
        {
            let scope = this;
            let container = ele.closest('.coming-soon-listing');
            scope.show_view_coming_soon_listing = true;
            scope.$refs.coming_soon_listing_div.innerHTML = `
                <div class="flex justify-center items-center h-full">
                    <i class="fa-duotone fa-spinner fa-spin fa-6x text-sky-500"></i>
                </div>
            `;
            axios.get('/agents/coming_soon/get_coming_soon_details/' + listing_id)
                .then(function (response)
                {
                    scope.$refs.coming_soon_listing_div.innerHTML = response.data;

                });

            if (document.querySelector('.coming-soon-listing.active'))
            {
                document.querySelector('.coming-soon-listing.active').classList.remove('bg-gray-100');
                document.querySelector('.coming-soon-listing').classList.remove('active');
            }
            container.classList.add('bg-gray-100');
            container.classList.add('active');
        },

        load_image_preview(photo_location, title = null, bright_image = null)
        {
            let scope = this;
            scope.$refs.image_title_div.innerHTML = '';
            if (title)
            {
                scope.$refs.image_title_div.innerHTML = title;
            }
            if (bright_image)
            {
                scope.$refs.image_preview_div.innerHTML = '<img src="' + photo_location + '" class="max-h-[700px] rounded shadow" alt="">';
            } else
            {
                scope.$refs.image_preview_div.innerHTML = '<img src="/storage/' + photo_location + '" class="max-h-[700px] rounded shadow" alt="">';
            }
            scope.show_image_preview = true;
        },

        view_map(address)
        {
            let scope = this;
            let src = scope.$refs.map_iframe.dataset.src;
            scope.$refs.map_iframe.src = src.replace('%%address%%', address);
            scope.show_map = true;
        },

        search_listings(el) {
            
            let scope = this;
            let search_value = el.value;

            if (search_value.length > 0)
            {
                scope.get_coming_soon(null, null, search_value);
            } else
            {
                scope.get_coming_soon();
            }
        },

        filter_by_city(el) {
            let scope = this;
            let city = el.value;
            scope.get_coming_soon(null, null, null, city);
        },

        pagination_links() {

            document.querySelectorAll('.pagination-link').forEach(function (link) {
                let params = new URLSearchParams(link.href.split('?')[1]);
                let page = params.get('page');
                link.dataset.page = page;
                link.setAttribute('href', 'javascript:void(0);');
            });
        },

        pagination_link_clicked(el)
        {
            let page = el.dataset.page;
            let per_page = el.closest('.pagination-nav').querySelector('.pagination-select').value;
            let search_value = this.$refs.coming_soon_search.value;
            this.get_coming_soon(page, per_page, search_value);
        },

        pagination_select_changed(el) {
            let per_page = el.value;
            let search_value = this.$refs.coming_soon_search.value;   
            this.get_coming_soon(1, per_page, search_value);
        },
    }
}